import { createRouter, createWebHistory } from "vue-router";
import {useAuthStore} from "@/store/auth";

const routes = [
  {
    path: "/",
    redirect: '/locker/use/list'
  },
  {
    path: "/login",
    name: "LoginPage",
    component: ()=>import('@/views/LoginPage.vue'),
  },
  {
    path: "/layout/header",
    name: "HeaderMenu",
    component: ()=>import('@/components/Layout/HeaderMenu.vue'),
  },
  {
    path: "/layout/footer",
    name: "FooterMenu",
    component: ()=>import('@/components/Layout/FooterMenu.vue'),
  },
  {
    path: "/layout/myinfo",
    name: "MyinfoLeftMenu",
    component: ()=>import('@/components/Layout/Myinfo_Left.vue'),
  },
  {
    path: "/layout/company",
    name: "CompanyLeftMenu",
    component: ()=>import('@/components/Layout/Company_Left.vue'),
  },
  {
    path: "/layout/user",
    name: "UserLeftMenu",
    component: ()=>import('@/components/Layout/User_Left.vue'),
  },
  {
    path: "/layout/locker",
    name: "LockerLeftMenu",
    component: ()=>import('@/components/Layout/Locker_Left.vue'),

  },
  {
    path: "/layout/member",
    name: "MemberLeftMenu",
    component: ()=>import('@/components/Layout/Member_Left.vue'),
  },
  {
    path: "/layout/admin",
    name: "AdminLeftMenu",
    component: ()=>import('@/components/Layout/Admin_Left.vue'),
  },
  {
    path: "/layout/monitoring",
    name: "MonitoringLeftMenu",
    component: ()=>import('@/components/Layout/Monitoring_Left.vue'),
  },
  {
    path: "/myinfo/management",
    name: "MyInfoManagement",
    component: ()=>import('@/views/myinfo/MyInfoManagement.vue'),
    children: [
      {
        path: "",
        name: "Default",
        component: ()=>import('@/views/myinfo/MyInfoEdit.vue'), // 기본적으로 렌더링될 컴포넌트
      },
      {
        path: "/myinfo/view",
        name: "MyInfoView",
        component: ()=>import('@/views/myinfo/MyInfoEdit.vue'),
      },
    ],
  },
  {
    path: "/company/management",
    name: "CompanyManagement",
    component: ()=>import('@/views/company/CompanyManagement.vue'),
    meta: {super:true},
    children: [
      {
        path: "",
        redirect: '/company/list'
        // name: "CompanyDefault",
        // component: CompanyList, // 기본적으로 렌더링될 컴포넌트
      },
      {
        path: "/company/list",
        name: "CompanyList",
        component: ()=>import('@/views/company/CompanyList.vue'),
      },
      {
        path: "/company/add",
        name: "CompanyAdd",
        component: ()=>import('@/views/company/CompanyAdd.vue'),
      },
      {
        path: "/company/view/:companyId", // 해당 페이지로 이동할때 파라미터가 필요할 경우
        name: "CompanyView",
        component: ()=>import('@/views/company/CompanyView.vue'),
        props: true,
      },
    ],
  },
  {
    path: "/user/management",
    name: "UserManagement",
    component: ()=>import('@/views/user/UserManagement.vue'),
    meta: {super:true},
    children: [
      {
        path: "",
        redirect:'/user/list'
        // name: "UserDefault",
        // component: UserList, // 기본적으로 렌더링될 컴포넌트
      },
      {
        path: "/user/list",
        name: "UserList",
        component: ()=>import('@/views/user/UserList.vue'),
      },
      {
        path: "/user/view/:userId",
        name: "UserView",
        component: ()=>import('@/views/user/UserView.vue'),
      },
      {
        path: "/user/withdraw/list",
        name: "UserWithdrawList",
        component: ()=>import('@/views/user/UserWithdrawList.vue'),
      },
    ],
  },
  {
    path: "/locker/management",
    name: "LockerManagement",
    component: ()=>import('@/views/locker/LockerManagement.vue'),
    children: [
      {
        path: "",
        redirect: '/locker/use/list'
        // name: "LockerDefault",
        // component: LockerUseStatus, // 기본적으로 렌더링될 컴포넌트
      },
      {
        path: "/locker/use/list",
        name: "LockerUseStatus",
        component: ()=>import('@/views/locker/use/LockerUseStatus.vue'),
      },
      {
        path: "/locker/use/view/:lockerId",
        name: "LockerUseStatusDetail",
        component: ()=>import('@/views/locker/use/LockerUseStatusDetail.vue'),
      },
      {
        path: "/locker/use/assignmenthistory",
        name: "LockerUseAssignmentHistory",
        component: ()=>import('@/views/locker/use/LockerUseAssignmentHistory.vue'),
      },
      {
        path: "/locker/use/controlstatus",
        name: "LockerUseControlStatus",
        component: ()=>import('@/views/locker/use/LockerUseControlStatus.vue'),
      },
      {
        path: "/locker/admin/list",
        name: "LockerAdminList",
        component: ()=>import('@/views/locker/admin/LockerAdminList.vue'),
      },
      {
        path: "/locker/admin/add",
        name: "LockerAdminAdd",
        component: ()=>import('@/views/locker/admin/LockerAdminAdd.vue'),
      },
      {
        path: "/locker/admin/view/:lockerId",
        name: "LockerAdminView",
        component: ()=>import('@/views/locker/admin/LockerAdminView.vue'),
      },
      {
        path: "/locker/admin/batchadd",
        name: "LockerAdminBatchAdd",
        component: ()=>import('@/views/locker/admin/LockerAdminBatchAdd.vue'),
      },
      {
        path: "/locker/area/list",
        name: "LockerAreaList",
        component: ()=>import('@/views/locker/area/LockerAreaList.vue'),
      },
      {
        path: "/locker/area/add",
        name: "LockerAreaAdd",
        component: ()=>import('@/views/locker/area/LockerAreaAdd.vue'),
      },
      {
        path: "/locker/area/view/:areaId",
        name: "LockerAreaView",
        component: ()=>import('@/views/locker/area/LockerAreaView.vue'),
      },
      {
        path: "/locker/relay/list",
        name: "LockerRelayList",
        component: ()=>import('@/views/locker/relay/LockerRelayList.vue'),
      },
      {
        path: "/locker/relay/add",
        name: "LockerRelayAdd",
        component: ()=>import('@/views/locker/relay/LockerRelayAdd.vue'),
      },
      {
        path: "/locker/relay/view/:tabletId",
        name: "LockerRelayView",
        component: ()=>import('@/views/locker/relay/LockerRelayView.vue'),
      },
    ],
  },
  {
    path: "/member/management",
    name: "MemberManagement",
    component: ()=>import('@/views/member/MemberManagement.vue'),
    children: [
      {
        path: "",
        redirect: '/member/list'
        // name: "MemberDefault",
        // component: MemberList, // 기본적으로 렌더링될 컴포넌트
      },
      {
        path: "/member/list",
        name: "MemberList",
        component: ()=>import('@/views/member/MemberList.vue'),
      },
      {
        path: "/member/add",
        name: "MemberAdd",
        component: ()=>import('@/views/member/MemberAdd.vue'),
      },
      {
        path: "/member/view/:memberId",
        name: "MemberView",
        component: ()=>import('@/views/member/MemberView.vue'),
        props: true,
      },
      {
        path: "/member/edit/:memberId",
        name: "MemberEdit",
        component: ()=>import('@/views/member/MemberEdit.vue'),
        props: true,
      },
      {
        path: "/member/approval/list",
        name: "MemberApprovalList",
        component: ()=>import('@/views/member/MemberApprovalList.vue'),
      },
    ],
  },
  {
    path: "/admin/management",
    name: "admin",
    component: ()=>import('@/views/admin/AdminManagement.vue'),
    children: [
      {
        path: "",
        redirect: '/admin/setting'
      },
      {
        path: "/admin/notice/list",
        name: "AdminNoticeList",
        component: ()=>import('@/views/admin/notice/AdminNoticeList.vue'),
        meta: {super:true},
      },
      {
        path: "/admin/notice/add",
        name: "AdminNoticeAdd",
        component: ()=>import('@/views/admin/notice/AdminNoticeAdd.vue'),
        meta: {super:true},
      },
      {
        path: "/admin/notice/view",
        name: "AdminNoticeView",
        component: ()=>import('@/views/admin/notice/AdminNoticeView.vue'),
        meta: {super:true},
      },
      {
        path: "/admin/privacy/list",
        name: "AdminPrivacyList",
        component: ()=>import('@/views/admin/privacy/AdminPrivacyList.vue'),
        meta: {super:true},
      },
      {
        path: "/admin/privacy/add",
        name: "AdminPrivacyAdd",
        component: ()=>import('@/views/admin/privacy/AdminPrivacyAdd.vue'),
        meta: {super:true},
      },
      {
        path: "/admin/privacy/view/:id",
        name: "AdminPrivacyView",
        component: ()=>import('@/views/admin/privacy/AdminPrivacyView.vue'),
        meta: {super:true},
      },
      {
        path: "/admin/termsofuse/list",
        name: "AdminTermsofuseList",
        component: ()=>import('@/views/admin/termsofuse/AdminTermsOfUseList.vue'),
        meta: {super:true},
      },
      {
        path: "/admin/termsofuse/add",
        name: "AdminTermsofuseAdd",
        component: ()=>import('@/views/admin/termsofuse/AdminTermsOfUseAdd.vue'),
        meta: {super:true},
      },
      {
        path: "/admin/termsofuse/view/:id",
        name: "AdminTermsofuseView",
        component: ()=>import('@/views/admin/termsofuse/AdminTermsOfUseView.vue'),
        meta: {super:true},
      },
      {
        path: "/admin/setting",
        name: "AdminSetting",
        component: ()=>import('@/views/admin/AdminSetting.vue'),
      },
      {
        path: "/admin/settingtime",
        name: "AdminSettingTime",
        component: ()=>import('@/views/admin/AdminSettingTime.vue'),
      },
    ],
  },
  {
    path: "/monitoring/management",
    name: "monitoring",
    component: ()=>import('@/views/monitoring/MonitoringManagement.vue'),
    children: [
      {
        path: "/monitoring/view",
        name: "MonitoringView",
        component: ()=>import('@/views/monitoring/MonitoringView.vue'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// 전역 네비게이션 가드
router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();
  if(to.path==="/login"){
    next();
    return;
  }
  if (!authStore.isLogin) {
    next("/login");
    return ;
  }
  if(to.meta.super && !authStore.isSuperAdmin){
    // alert('접근 권한이 없는 관리자 입니다.');
    next("/");
    return;
  }

  // 새로고침 될 때에 전역상태에 companyId가 null 들어가면서 조회시 로그인된 업체id에대한 조건이 없어지는 경우방지
  try {
    // companyId가 없을 경우에만 hydrate
    if (authStore.companyId === null) {
      await authStore.hydrate();
    }
    next(); // 모든 조건을 통과했을 때만 라우팅
  } catch (error) {
    next("/login"); // 에러가 발생하면 로그인으로 리다이렉트
  }
});

export default router;
